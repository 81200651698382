<template>
  <aside class="error-banner" v-class-mod:error-banner="{ removeBorder }">
    <span class="error-banner__message">{{ translations.errorBanner.message }}</span>
    <cta-button
      :title="btnLabel"
      :stylingOptions="{
        bg: 'static-black-90',
        bgHover: 'static-black-90',
        color: 'static-white-90',
        colorHover: 'static-white-90',
        iconBeforeColor: 'static-white-50',
        iconBeforeColorHover: 'static-white',
      }"
      :action="retry"
      icon-before="refresh"
      class="error-banner__btn"
    />
  </aside>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

import { interpolate } from "@horizon56/translations";

import { translations } from "@/infrastructure/translations";

import CtaButton from "../buttons/cta-button.vue";

const props = defineProps<{ autoRetry?: number; removeBorder?: boolean }>();
const emit = defineEmits<{ (e: "retry"): void }>();

const interval = ref(-1);
const secondsLeft = ref(-1);

const btnLabel = computed(
  () =>
    `${translations.errorBanner.tryAgain}${
      props.autoRetry ? ` ${interpolate(translations.errorBanner.autoRetry, { n: secondsLeft.value })}` : ""
    }`,
);

const retry = () => {
  emit("retry");
  stopInterval();
};

const stopInterval = () => window.clearInterval(interval.value);

onMounted(() => {
  if (props.autoRetry) {
    secondsLeft.value = props.autoRetry;
    interval.value = window.setInterval(() => {
      secondsLeft.value--;
      if (secondsLeft.value <= 0) {
        stopInterval();
        emit("retry");
      }
    }, 1000);
  }
});

onBeforeUnmount(stopInterval);
</script>

<style lang="scss" scoped>
.error-banner {
  background: var(--yellow-vivid);
  padding: var(--app-spacing-size-xsmall) var(--app-spacing-size-small);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  container-type: inline-size;
  &:not(&--removeBorder) {
    border-radius: var(--app-radius-medium);
  }
  &__message {
    color: var(--static-black);
    padding: 0 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    min-height: var(--app-button-height-medium);
    flex-grow: 1;
    @include icon-before($icon-warning_amber) {
      font-size: var(--app-icon-size-medium);
      margin-right: 4px;
    }
  }
  &__btn {
    margin: 0 10px;
  }
}
</style>
